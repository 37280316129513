import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DashboardRoutes from "./dashboard_route";
import { PrivateRoute } from "./private_route";
import Login from "../pages/Login";
import PageNotFound from "../pages/PageNotFound";
import SignUp from "../pages/SignUp";
import RetrievePassword from "../pages/RetrievePassword";

export const IndexRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup/:accountType" element={<SignUp />} />
        {/* "/* in reset-password allows the use of the '-' character" */}
        <Route path="/reset-password/*" element={<RetrievePassword />} />
        <Route
          path="/dashboard/*"
          element={
            <PrivateRoute>
              <DashboardRoutes />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default IndexRoutes;
