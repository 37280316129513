import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import Button from "../components/forms/button/Button";
import InputField from "../components/forms/input_field/InputField";
import AuthLayout from "../components/layouts/authentication/AuthLayout";
// import Modal from "../components/modal/Modal";
import { APP_CONTENT } from "../config/languages/content_loader";
import style from "./Login.module.css";
import { BackIcon } from "../assets/icons/icons";
import useLogin from "../hooks/login/useLogin";

export const RetrievePasswordForm = () => {
  const {
    label1,
    label2,
    label3,
    label4,
    label5,
    label6,
    label7,
    label8,
    label9,
  } = APP_CONTENT.retrievePasswordContent;

  const [getOptClicked, setGetOtpClicked] = useState(false);
  const { navigate } = useLogin();

  return (
    <div className={style.login}>
      <p className={style.back} onClick={() => navigate("/")}>
        <BackIcon size={20} />
        BACK
      </p>
      <h1>{label1}</h1>
      <div className={style.form}>
        {!getOptClicked && (
          <>
            <InputField
              label={label2}
              name={label2}
              type="email"
              placeholder=""
              classes={style.input}
              callbackFn={(e) => console.log(e)}
            />
            <Button
              text={label9}
              onClick={() => setGetOtpClicked((prev) => !prev)}
            />
          </>
        )}
        {getOptClicked && (
          <>
            <InputField
              label={label3}
              name={label3}
              type="text"
              placeholder=""
              classes={style.input}
              callbackFn={(e) => console.log(e)}
            />
            <InputField
              label={label4}
              name={label4}
              type="password"
              placeholder=""
              classes={style.input}
              callbackFn={(e) => console.log(e)}
            />
            <InputField
              label={label5}
              name={label5}
              type="password"
              placeholder=""
              classes={style.input}
              callbackFn={(e) => console.log(e)}
            />
            <Button text={label6} onClick={() => {}} />
          </>
        )}

        <p className={style.center}>
          {label7}
          <span className={style.link}>{label8}</span>
        </p>
      </div>
      {/* <Modal
        Component={AccountType}
        isActive={modal}
        onClick={() => toggleModal}
      /> */}
    </div>
  );
};

const RetrievePassword = () => <AuthLayout Component={RetrievePasswordForm} />;

export default RetrievePassword;
