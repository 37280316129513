import { PlayIcon } from "../../assets/icons/icons";
import { APP_CONTENT } from "../../config/languages/content_loader";
import { Card } from "../layouts/card/Card";
import { useState } from "react";
import style from "./HelpResources.module.css";
import Modal from "../modal/Modal";
export const VideoView = (props) => {
  return (
    <div className={style.root}>
      <h4>{props.data.title}</h4>
      <div>
        <iframe
          src={props.data.url}
          frameborder="0"
          allowfullscreen
          title={props.data.title}
        ></iframe>
      </div>
    </div>
  );
};

export const HelpList = () => {
  const { title, list } = APP_CONTENT.helpResourcesContent;
  const [toggleModal, setToggleModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState({
    title: "",
    url: "",
  });
  const viewResourceHandler = (data) => {
    setSelectedResource(data);
    setToggleModal(true);
  };
  return (
    <>
      <h3>{title}</h3>
      <ul className={style.list}>
        {list.map((item, index) => (
          <li
            key={index}
            onClick={() =>
              viewResourceHandler({ title: item.label, url: item.url })
            }
          >
            <PlayIcon color="green" size={20} />
            {item.label}
          </li>
        ))}
      </ul>
      <Modal
        Component={VideoView}
        classes={style.modal}
        onClick={() => setToggleModal(false)}
        isActive={toggleModal}
        data={selectedResource}
      />
    </>
  );
};

const HelpResources = () => {
  return <Card Component={HelpList} classes={style.bg} />;
};

export default HelpResources;
