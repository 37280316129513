export const initCaps = (str) => {
  return str.replace(/\b\w/g, (match) => match.toUpperCase());
};

export const dateExtractor = (dateString) => {
  const dateArr = dateString.split("-");
  const dateObject = {};
  if (dateArr?.length > 0) {
    dateObject["year"] = parseInt(dateArr[0]);
    dateObject["month"] = parseInt(dateArr[1]);
    dateObject["day"] = parseInt(dateArr[2]);
  }
  return dateObject;
};

export const isValidInput = (arr) => {
  let status = true;
  arr.forEach((item) => {
    if (item === undefined || item?.length < 1) {
      status = false;
      return;
    }
  });
  return status;
};

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isValidDate = (_date) => {
  const pattern = /YYYY|MM|DD/;
  if (!!_date === true && !pattern.test(_date)) {
    return true;
  }
  return false;
};

export const isValidNumber = (_number) => {
  // Allow digits, optional decimal point, and optional comma as thousand separator
  // const pattern = /^(\d+([.,]\d{1,3})?|\d{1,3}(,\d{3})*([.,]\d{1,3})?)$/;
  const pattern = /^(\d{1,3}(,\d{3})*(\.\d{1,3})?|\d+(\.\d{1,3})?)$/;

  // Check if the number matches the pattern
  return pattern.test(_number);
};
