import Card from "../card/Card";
import { ColumnLayout, RowLayout } from "../layouts/util/UtilityLayout";
import style from "./ClaimSummary.module.css";

const ClaimSummary = () => {
  return (
    <Card>
      <RowLayout classes={style.mobileLayout}>
        <ColumnLayout>
          <h3>ILLNESS</h3>
          <p>Back pain</p>
          <p>POLICYNUMBER HERE</p>
          <h4>
            currency <span>200</span>
          </h4>
          <div>
            <span className={`${style.tab} ${style["pending"]}`}>pending</span>
          </div>
        </ColumnLayout>
        <ColumnLayout>
          <p>Activities</p>
          <ul className={style.activity}>
            <li>
              <p className={style.activityTitle}>Claim Settled</p>
              <p className={style.activityDate}>03-01-2023</p>
            </li>
            <li>
              <p className={style.activityTitle}>Claim Authorized</p>
              <p className={style.activityDate}>03-01-2023</p>
            </li>
            <li>
              <p className={style.activityTitle}>Claim Approved</p>
              <p className={style.activityDate}>03-01-2023</p>
            </li>
            <li>
              <p className={style.activityTitle}>Claim submitted</p>
              <p className={style.activityDate}>03-01-2023</p>
            </li>
          </ul>
        </ColumnLayout>
      </RowLayout>
    </Card>
  );
};
export default ClaimSummary;
