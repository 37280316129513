import dayjs from "dayjs";
import { useState, useEffect, useRef } from "react";
import style from "./DatePicker.module.css";
import InputField from "../input_field/InputField";
import { toast } from "react-toastify";
import { initCaps } from "../../../services/util_functions";
import { CaretDownIcon, CaretUpIcon } from "../../../assets/icons/icons";

export const Display = ({
  data,
  callback,
  classes,
  isActive = false,
  disabled = false,
  listClasses,
}) => {
  const [optionSelected, setOptionSelected] = useState(data.value);

  // console.log(data);

  useEffect(() => {
    setOptionSelected(data.value);
  }, [data.value]);

  const ListGenerator = {
    year: () => {
      // use min and max value to generate list
      const yearRange = [...new Array(data.max).fill(data.min)].map(
        (item, index) => item - index
      );
      return yearRange;
    },
    month: () => {
      const monthRange = [...new Array(data.max).fill(1)].map(
        (item, index) => item + index
      );
      return monthRange;
    },

    day: () => {
      const maxDays =
        dayjs(`${data.year}-${data.month}-01`).daysInMonth() || 30;
      const dayRange = [...new Array(maxDays).fill(1)].map(
        (item, index) => item + index
      );
      return dayRange;
    },
  };

  const updateActiveStateHandler = () =>
    callback({ displayType: { [data.name]: !isActive } });

  const updateSelectionHandler = (selectedOption) => {
    setOptionSelected(selectedOption);

    // If selection is month, reset day to 1 and if it is year reset day and month to 1
    const dateValue =
      // data.name === "year"
      //   ? { [data.name]: selectedOption, month: 1, day: 1 }
      //   :
      data.name === "month"
        ? { [data.name]: selectedOption, day: 1 }
        : { [data.name]: selectedOption };

    callback({
      displayType: { [data.name]: !isActive },
      date: dateValue,
    });
  };

  const changeHandler = (response) => {
    const res = response.value;
    switch (data.name) {
      case "day": {
        const maxDays = dayjs(`${data.year}-${data.month}-01`).daysInMonth();
        if (parseInt(res) > maxDays || parseInt(res) < 1) {
          toast.error("Invalid day entered");
        }
        return;
      }
      case "month": {
        if (parseInt(res) > 12 || parseInt(res) < 1) {
          toast.error("Invalid month entered");
        }
        return;
      }
      case "year": {
        if (parseInt(res) > data.max || parseInt(res) < data.min) {
          // toast.error("Invalid year entered");
        }
        return;
      }
      default: {
      }
    }

    // If selection is month, reset day to 1 and if it is year reset day and month to 1
    const dateValue =
      // data.name === "year"
      //   ? { [data.name]: res, month: 1, day: 1 }
      //   :
      data.name === "month"
        ? { [data.name]: res, day: 1 }
        : { [data.name]: res };

    callback({
      displayType: { [data.name]: !isActive },
      date: dateValue,
    });
  };

  return (
    <div>
      <p className={style.title}>{initCaps(data.name)}</p>
      <div className={style.display} onClick={updateActiveStateHandler}>
        <InputField
          value={optionSelected}
          classes={style.input}
          callbackFn={changeHandler}
          disabled={disabled}
        />
        <span className={style.icon}>
          {isActive ? <CaretUpIcon /> : <CaretDownIcon />}
        </span>
      </div>
      {isActive && (
        <List
          data={ListGenerator[data.name]()}
          updateSelection={updateSelectionHandler}
          listClasses={listClasses}
        />
      )}
    </div>
  );
};

export const List = ({ data, updateSelection, listClasses }) => {
  return (
    <div className={`${style.listWrapper} ${listClasses}`}>
      <ul className={`${style.list}`}>
        {data.map((item, index) => (
          <li key={index} onClick={() => updateSelection(item)}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const DatePicker = ({
  label,
  classes = "",
  name = "value",
  defaultValue = {},
  minDate = {
    year: dayjs().year(),
    month: dayjs().month() + 1,
    day: dayjs().date(),
  },
  required = false,
  callback = () => {},
}) => {
  const [isActive, setActiveDisplay] = useState({
    year: false,
    month: false,
    day: false,
  });
  const dropdownRef = useRef(null);

  const [dateData, setDateData] = useState(
    defaultValue.year
      ? defaultValue
      : {
          year: "YYYY",
          month: "MM",
          day: "DD",
        }
  );

  // Allows closing modal by clicking body of the page
  useEffect(() => {
    const handleGlobalClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDisplay({});
      }
    };

    const handleDocumentClick = () => {
      document.addEventListener("mousedown", handleGlobalClick);
    };

    handleDocumentClick();

    return () => {
      document.removeEventListener("mousedown", handleGlobalClick);
    };
  }, []);

  const updateDataHandler = (data) => {
    setActiveDisplay((prev) => data.displayType);
    callback({ [name]: { ...dateData, ...data.date } });
    setDateData((prev) => ({ ...prev, ...data.date }));
  };

  // logic for minDate will use the year and month and day but for other year, and month, it should allow any pick
  return (
    <div ref={dropdownRef}>
      <p>
        {label} {required && <span>*</span>}
      </p>
      <div className={style.rootDisplay}>
        <Display
          data={{
            name: "year",
            value: dateData.year,
            min: minDate.year,
            max: 90, // fixed value and can be changed to meet user needs
          }}
          isActive={isActive.year}
          callback={updateDataHandler}
          listClasses={classes}
        />
        <Display
          data={{
            name: "month",
            value: dateData.month,
            min: minDate.month,
            max: 12,
          }}
          isActive={isActive.month}
          callback={updateDataHandler}
          listClasses={classes}
        />
        <Display
          data={{
            name: "day",
            value: dateData.day,
            year: dateData.year,
            month: dateData.month, //get the total days for the month
          }}
          isActive={isActive.day}
          callback={updateDataHandler}
          listClasses={classes}
        />
      </div>
    </div>
  );
};
export default DatePicker;
