import { Route, Routes } from "react-router-dom";
import AddClaim from "../pages/add_claim/AddClaim";
import Dashboard from "../pages/dashboard/Dashboard";

const NotFound = () => <h2>Page not found</h2>;

const DashboardRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/add-claim" element={<AddClaim />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default DashboardRoutes;
