import { useState } from "react";
import Button from "../../../components/forms/button/Button";
import RadioButton from "../../../components/forms/radio_button/RadioButton";
import style from "./Screens.module.css";
import { toast } from "react-toastify";
import { RowLayout } from "../../../components/layouts/util/UtilityLayout";
import Alert from "../../../components/alert/Alert";

const Screen5 = ({ labels, updatePage, save, defaultValue, generalLabel }) => {
  const { label1, label2, label3 } = labels;
  const [form, setForm] = useState(defaultValue);

  const prevScreenHandler = () => updatePage(3);
  const nextScreenHandler = () => {
    //  validation
    if (!form?.isFirstClaim?.label || !form?.isUnderExcess?.label) {
      toast.error(`Select an answer for both questions respectively`);
    } else {
      save({ screen5: form });
      updatePage(5);
    }
  };

  return (
    <div className={style.screenScrollable}>
      <p className={`${style.title} ${style.bigFont}`}>
        {`${label1} ${form.petName}`} <span>*</span>
      </p>

      <RadioButton
        labels={[generalLabel.label1, generalLabel.label2]}
        type="radio"
        classesLabel={style.shrink}
        classesInput={style.shrinkInput}
        selectedOption={form?.isFirstClaim}
        noGap={true}
        display="row"
        parentName={label1}
        callbackFn={(data) => {
          setForm((prev) => ({ ...prev, isFirstClaim: data }));
        }}
      />

      <p className={`${style.title} ${style.bigFont}`}>
        {`${label2} ***CURRENCYHERE*** ${form.excessAmount}`} <span>*</span>
      </p>

      <RadioButton
        labels={[generalLabel.label1, generalLabel.label2]}
        type="radio"
        classesLabel={style.shrink}
        classesInput={style.shrinkInput}
        selectedOption={form?.isUnderExcess}
        noGap={true}
        display="row"
        parentName={label2}
        callbackFn={(data) => {
          setForm((prev) => ({ ...prev, isUnderExcess: data }));
        }}
      />

      <Alert message={label3} type="info" />

      <RowLayout classes={style.gap20}>
        <Button
          text={generalLabel.label4}
          onClick={prevScreenHandler}
          classes={style.btn}
        />
        <Button
          text={generalLabel.label3}
          onClick={nextScreenHandler}
          classes={style.btn}
        />
      </RowLayout>
    </div>
  );
};
export default Screen5;
