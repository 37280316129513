import Header from "../../header/Header";
import HelpResources from "../../help_resources/HelpResources";
import { ColumnLayout, RowLayout } from "../util/UtilityLayout";
import style from "./DashboardLayout.module.css";

const DashboardLayout = ({ Component }) => {
  return (
    <>
      <Header />
      <RowLayout classes={style.component}>
        <ColumnLayout>{<Component />}</ColumnLayout>
        <ColumnLayout classes={style.alignRight}>
          <HelpResources />
        </ColumnLayout>
      </RowLayout>
    </>
  );
};
export default DashboardLayout;
