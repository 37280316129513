import Button from "../../../components/forms/button/Button";
import style from "./Screens.module.css";
import { RowLayout } from "../../../components/layouts/util/UtilityLayout";
import Alert from "../../../components/alert/Alert";
import useLogin from "../../../hooks/login/useLogin";

const Screen11 = ({ labels, updatePage, generalLabel }) => {
  const { label1, label2 } = labels;
  const { navigate } = useLogin();

  const closeHandler = () => {
    updatePage(0);
    navigate("/dashboard");
  };

  return (
    <div className={style.screenScrollable}>
      <p>{label1}</p>
      <p className={style.mt20}>{label2}</p>
      <Alert message="HES-DE03982" />

      <RowLayout classes={style.gap20}>
        <Button
          text={generalLabel.label11}
          onClick={closeHandler}
          classes={style.btn}
        />
      </RowLayout>
    </div>
  );
};
export default Screen11;
