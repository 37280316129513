import { useState } from "react";
import Button from "../../../components/forms/button/Button";
import InputField from "../../../components/forms/input_field/InputField";
import RadioButton from "../../../components/forms/radio_button/RadioButton";
import style from "./Screens.module.css";
import { toast } from "react-toastify";
import { RowLayout } from "../../../components/layouts/util/UtilityLayout";
import { isValidInput } from "../../../services/util_functions";

const Screen9 = ({ labels, updatePage, save, defaultValue, generalLabel }) => {
  const {
    label1,
    label2,
    label3,
    label4,
    label5,
    label6,
    label8,
    label9,
    label10,
    label11,
    label12,
    label13,
    label14,
  } = labels;
  const [form, setForm] = useState(defaultValue);

  console.log(form);

  const [innerPage, setInnerPage] = useState(
    defaultValue?.paymentOption?.index !== undefined
      ? defaultValue?.paymentOption?.index + 1 // +1 will make up for the index position
      : 0
  );

  const prevScreenHandler = () => updatePage(7);
  const prevPage = () => setInnerPage(0);

  const nextPage = () => {
    //  validation
    console.log("form ", form);
    if (!form?.paymentOption?.label) {
      toast.error(`Select an option.`);
    } else {
      setInnerPage((prev) => form?.paymentOption?.index + 3);
    }
  };
  console.log("INNER PAGE ", innerPage, form?.paymentOption?.index);

  const nextScreenHandler = () => {
    console.log("screen 9 ", form);
    // validate user info
    if (
      form?.paymentOption?.index === 0 &&
      !form?.isAccountNumberCorrect?.label
    ) {
      toast.error("Select an option");
    } else if (
      form?.paymentOption?.index === 0 &&
      form?.isAccountNumberCorrect?.index === 1 &&
      !isValidInput([form?.newAccountNumber])
    ) {
      toast.error("Enter New account number");
    } else if (
      form?.paymentOption?.index === 1 &&
      !isValidInput([form?.vetAccountNumber, form?.vetName])
    ) {
      // validate vet info
      toast.error("All fields with * are required");
    } else {
      updatePage(9);
      save({ screen9: form });
    }
  };

  return (
    <div className={style.screenScrollable}>
      {innerPage < 3 && (
        <>
          <p className={style.mb20}>
            {label1} <span>*</span>
          </p>
          <RadioButton
            labels={[label2, label4]}
            selectedOption={form?.paymentOption}
            type="checkbox"
            classesLabel={style.shrink}
            classesInput={style.shrinkInput}
            noGap={true}
            display="column"
            callbackFn={(data) => {
              if (data.index === 0 || data.index === 1) {
                setInnerPage(data.index + 1);
              } else {
                // If currentIndex is not 0 or 1, go back to the first page
                setInnerPage(0);
              }
              setForm((prev) => ({ paymentOption: data }));
            }}
            moreDetails={[
              { message: label3, type: "alert" },
              { message: label5, type: "alert" },
            ]} //type = alert or ""
          />
        </>
      )}
      {/* 3 and 4 is used to prevent the form from showing without the next button clicked. */}
      {innerPage === 3 && (
        <>
          <h4>{label2}</h4>
          <InputField
            label={label6}
            type="text"
            value="012345879"
            placeholder=""
            classes={style.input}
            disabled={true}
            whiteDisabledBg={true}
          />
          <p>{label8}</p>
          <RadioButton
            labels={[generalLabel.label1, generalLabel.label2]}
            selectedOption={form?.isAccountNumberCorrect}
            type="radio"
            classesLabel={style.shrink}
            classesInput={style.shrinkInput}
            noGap={true}
            display="row"
            parentName={label8}
            callbackFn={(data) =>
              setForm((prev) => ({ ...prev, isAccountNumberCorrect: data }))
            }
          />

          {form?.isAccountNumberCorrect?.label === "No" && (
            <div>
              <p>
                {label9} <span className={style.small}>{`(${label10})`}</span>
              </p>
              <InputField
                label={label11}
                value={form?.newAccountNumber}
                type="text"
                name="newAccountNumber"
                placeholder=""
                required={true}
                classes={style.input}
                callbackFn={(value) =>
                  setForm((prev) => ({ ...prev, ...value }))
                }
              />
            </div>
          )}
        </>
      )}
      {innerPage === 4 && (
        <>
          <h4>{label12}</h4>
          <InputField
            label={label13}
            value={form?.vetName}
            type="text"
            name="vetName"
            placeholder="Enter Details"
            required={true}
            classes={style.input}
            callbackFn={(value) => setForm((prev) => ({ ...prev, ...value }))}
          />
          <InputField
            label={label14}
            value={form?.vetAccountNumber}
            type="text"
            name="vetAccountNumber"
            showValidationIcon={true}
            placeholder="Enter Details"
            required={true}
            classes={style.input}
            callbackFn={(value) => setForm((prev) => ({ ...prev, ...value }))}
          />
        </>
      )}
      <RowLayout classes={style.gap20}>
        <Button
          text={generalLabel.label4}
          onClick={innerPage >= 3 ? prevPage : prevScreenHandler}
          classes={style.btn}
        />
        <Button
          text={innerPage < 3 ? generalLabel.label3 : generalLabel.label10}
          onClick={innerPage < 3 ? nextPage : nextScreenHandler}
          classes={innerPage < 3 ? style.btn : style.btnSubmit}
        />
      </RowLayout>
    </div>
  );
};
export default Screen9;
