import { useState } from "react";
import { RowLayout } from "../../layouts/util/UtilityLayout";
import style from "./RadioButton.module.css";
import Alert from "../../alert/Alert";
const RadioButton = ({
  selectedOption = {},
  labels,
  type,
  classesLabel,
  classesInput,
  callbackFn,
  noGap,
  display = "column",
  parentName = "",
  moreDetails = [], //[{ message: "", type: "alert" }] type can also be ""
  ...rest
}) => {
  const [selection, setSelection] = useState(selectedOption);

  // useEffect(
  //   () => {
  //     callbackFn(selection);
  //   },
  //   // eslint-disable-next-line
  //   [selection]
  // );

  const changeHandler = (status, index, label) => {
    if (status) {
      callbackFn({ index, label, parentName });
      setSelection({ index, label, parentName });
    } else {
      callbackFn({ index: null, label: "", parentName });
      setSelection({ index: null, label: "", parentName });
    }
  };

  return (
    <>
      <div className={display === "row" ? style.row : style.none}>
        {labels.map((label, index) => (
          <div key={index}>
            <RowLayout classes={!noGap ? style.gap : ""} key={index}>
              <p className={`${style.label} ${classesLabel}`}>{label}</p>
              <input
                type={type}
                checked={selection.index === index}
                onChange={(e) => changeHandler(e.target.checked, index, label)}
                className={`${style.input} ${classesInput}`}
              />
            </RowLayout>
            <div>
              {!!moreDetails[index]?.message ? (
                moreDetails[index]?.type === "alert" ? (
                  <Alert message={moreDetails[index]?.message} type="info" />
                ) : (
                  <span
                    className={style.moreInfo}
                  >{`(${moreDetails[index]?.message})`}</span>
                )
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default RadioButton;
