import { APP_CONTENT } from "../../../config/languages/content_loader";
import { ColumnLayout, RowLayout } from "../util/UtilityLayout";
import style from "./AuthLayout.module.css";

const AuthLayout = ({ Component }) => {
  const { label9 } = APP_CONTENT.loginContent;
  return (
    <RowLayout>
      <ColumnLayout>
        {/* mobile only */}
        <RowLayout classes={style.mtop}>
          <p>Petcover Group</p>
          <h2 className={style.message}>{label9}</h2>
        </RowLayout>
        {<Component />}
      </ColumnLayout>
      <ColumnLayout classes={style.side}>
        <p>Petcover Group</p>
        <h1 className={style.message}>{label9}</h1>
      </ColumnLayout>
    </RowLayout>
  );
};
export default AuthLayout;
