import { useState } from "react";
import Button from "../../../components/forms/button/Button";
import RadioButton from "../../../components/forms/radio_button/RadioButton";
import style from "./Screens.module.css";
import { toast } from "react-toastify";
import { RowLayout } from "../../../components/layouts/util/UtilityLayout";

const Screen10 = ({
  labels,
  updatePage,
  save,
  defaultValue,
  generalLabel,
  clear,
  allData,
}) => {
  const { label1, label2 } = labels;
  const [form, setForm] = useState(defaultValue);

  const prevScreenHandler = () => updatePage(8);
  const nextScreenHandler = () => {
    //  validation
    if (form?.index === 0) {
      save({ screen10: form });
      // TODO:: get all the records and send to api
      // if api response is successful, clear redux
      updatePage(10);
      console.log({ ...allData, screen10: form });
      clear();
    } else {
      toast.error(`Tick the authorization box`);
    }
  };

  return (
    <div className={style.screenScrollable}>
      <p>
        {label1} <span>*</span>
      </p>
      <p className={style.mt20}>{label2}</p>
      <RadioButton
        labels={[""]}
        selectedOption={form}
        type="checkbox"
        classesInput={style.shrinkInput}
        noGap={true}
        display="row"
        parentName="termsAndConditions"
        callbackFn={(data) => setForm(data)}
      />

      <RowLayout classes={style.gap20}>
        <Button
          text={generalLabel.label4}
          onClick={prevScreenHandler}
          classes={style.btn}
        />
        <Button
          text={generalLabel.label10}
          onClick={nextScreenHandler}
          classes={style.btnSubmit}
        />
      </RowLayout>
    </div>
  );
};
export default Screen10;
