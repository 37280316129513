import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  screenIndex: 0,
  form: {},
};

export const claimSlice = createSlice({
  name: "claimSlice",
  initialState,
  reducers: {
    updateScreenIndex: (state, action) => {
      return {
        ...state,
        screenIndex: action.payload,
      };
    },
    updateForm: (state, action) => {
      return {
        ...state,
        form: { ...state.form, ...action.payload },
      };
    },
    clearForm: (state, action) => {
      return {
        ...state,
        form: {},
      };
    },
  },
});

export const { updateScreenIndex, updateForm, clearForm } = claimSlice.actions;

export default claimSlice.reducer;
