import { Link } from "react-router-dom";
import { RowLayout } from "../layouts/util/UtilityLayout";
import style from "./Header.module.css";
import LanguageSelector from "../language_selector/LanguageSelector";
import MessageBox from "../message_box/MessageBox";
import Profile from "../profile/Profile";

const Header = () => {
  return (
    <>
      {" "}
      <RowLayout classes={style.header}>
        <div className={style.title}>
          <Link to="/dashboard">EasyClaim</Link>
        </div>
        <RowLayout classes={style.actions}>
          <LanguageSelector />
          <MessageBox />
          <Profile />
        </RowLayout>
      </RowLayout>
    </>
  );
};

export default Header;
