import style from "./UtilityLayout.module.css";

export const RowLayout = ({ children, classes }) => {
  return <div className={`${style.row} ${classes}`}>{children}</div>;
};
export const ColumnLayout = ({ children, classes }) => {
  return <div className={`${style.column} ${classes}`}>{children}</div>;
};

export const CardLayout = ({ children, classes }) => {
  return <div className={`${style.card} ${classes}`}>{children}</div>;
};
