import {
  MailIcon,
  ReadMessageIcon,
  UnreadMessageIcon,
} from "../../assets/icons/icons";
import Modal from "../modal/Modal";
import style from "./MessageBox.module.css";
import { useState } from "react";

export const Messages = () => {
  return (
    <ul className={style.list}>
      <li>
        <UnreadMessageIcon size={20} color="orange" /> Claims cancellation
        notice ...
      </li>
      <li>
        <ReadMessageIcon color="green" size={20} /> Claim for policy #34543 has
        ...
      </li>
    </ul>
  );
};

const MessageBox = ({ classes }) => {
  const [active, setActive] = useState(false);
  return (
    <div
      className={`${style.root} ${classes}`}
      onClick={() => setActive((prev) => !prev)}
    >
      <MailIcon size={20} /> <div className={style.counter}>0</div>
      <Modal
        Component={Messages}
        isActive={active}
        classes={style.modal}
        hasCloseBtn={false}
      />
    </div>
  );
};

export default MessageBox;
