import { useState } from "react";
import Button from "../../../components/forms/button/Button";
import RadioButton from "../../../components/forms/radio_button/RadioButton";
import style from "./Screens.module.css";
import { toast } from "react-toastify";
import { RowLayout } from "../../../components/layouts/util/UtilityLayout";
import Alert from "../../../components/alert/Alert";

const Screen6 = ({ labels, updatePage, save, defaultValue, generalLabel }) => {
  const { label1, label2, label3, label4 } = labels;
  const [form, setForm] = useState(defaultValue);

  const prevScreenHandler = () => updatePage(4);
  const nextScreenHandler = () => {
    //  validation
    if (!form?.claimType?.label) {
      toast.error(`Select an option.`);
    } else {
      save({ screen6: form });
      updatePage(6);
    }
  };

  return (
    <div className={style.screenScrollable}>
      <p className={`${style.title} ${style.bigFont} ${style.mb20}`}>
        {`${label1} ${form.petName}`} <span>*</span>
      </p>
      <RadioButton
        labels={[label2, label3]}
        type="checkbox"
        selectedOption={form?.claimType}
        classesLabel={style.shrink}
        classesInput={style.shrinkInput}
        noGap={true}
        display="column"
        parentName={label1}
        callbackFn={(data) => setForm((prev) => ({ ...prev, claimType: data }))}
      />

      <Alert message={`${label4} ${form.customerCareNumber}`} type="warning" />

      <RowLayout classes={style.gap20}>
        <Button
          text={generalLabel.label4}
          onClick={prevScreenHandler}
          classes={style.btn}
        />
        <Button
          text={generalLabel.label3}
          onClick={nextScreenHandler}
          classes={style.btn}
        />
      </RowLayout>
    </div>
  );
};
export default Screen6;
