import { BackIcon } from "../assets/icons/icons";
import Button from "../components/forms/button/Button";
import InputField from "../components/forms/input_field/InputField";
import AuthLayout from "../components/layouts/authentication/AuthLayout";
import { APP_CONTENT } from "../config/languages/content_loader";
import useLogin from "../hooks/login/useLogin";
import style from "./Login.module.css";
import { useParams } from "react-router-dom";

export const SignUpForm = () => {
  const {
    label1,
    label2,
    label3,
    label4,
    label5,
    label6,
    label7,
    label8,
    label9,
    label10,
    label11,
  } = APP_CONTENT.registratonFormContent;

  const { accountType } = useParams();
  const { navigate } = useLogin();

  return (
    <div className={style.signUp}>
      <p className={style.back} onClick={() => navigate("/")}>
        <BackIcon size={20} />
        BACK
      </p>
      <h1>{label1}</h1>
      <p className={style.subheading}>
        {label2} <span>{accountType}</span>
      </p>
      <div className={style.formSignup}>
        {accountType === "customer" ? (
          <InputField
            label={label3}
            name={label3}
            type="text"
            placeholder=""
            classes={style.input}
            callbackFn={(e) => console.log(e)}
          />
        ) : (
          <InputField
            label={label4}
            name={label4}
            type="text"
            placeholder=""
            classes={style.input}
            callbackFn={(e) => console.log(e)}
          />
        )}
        <InputField
          label={label5}
          name={label5}
          type="email"
          placeholder=""
          classes={style.input}
          callbackFn={(e) => console.log(e)}
        />
        {accountType === "customer" && (
          <InputField
            label={label6}
            name={label6}
            type="email"
            placeholder=""
            classes={style.input}
            callbackFn={(e) => console.log(e)}
          />
        )}
        <InputField
          label={label7}
          name={label7}
          type="password"
          placeholder=""
          classes={style.input}
          callbackFn={(e) => console.log(e)}
        />
        <InputField
          label={label8}
          name={label8}
          type="password"
          placeholder=""
          classes={style.input}
          callbackFn={(e) => console.log(e)}
        />
        <Button text={label9} onClick={() => {}} />
        <p className={style.center}>
          {label10}
          <span className={style.link} onClick={() => navigate("/")}>
            {label11}
          </span>
        </p>
      </div>
    </div>
  );
};

const SignUp = () => <AuthLayout Component={SignUpForm} />;

export default SignUp;
