import {
  CaretDownIcon,
  CaretUpIcon,
  LocationIcon,
  LogoutIcon,
  MailIcon,
  PasswordIcon,
  UserIcon,
  WorldIcon,
} from "../../assets/icons/icons";
import useLogin from "../../hooks/login/useLogin";
import Modal from "../modal/Modal";
import style from "./Profile.module.css";
import { useState } from "react";

export const Settings = () => {
  const { navigate } = useLogin();
  return (
    <ul className={style.list}>
      <li>
        <MailIcon /> Inbox
      </li>
      <li onClick={() => navigate("/reset-password")}>
        <PasswordIcon /> Change Password
      </li>
      <li>
        <WorldIcon />
        Update Language
      </li>
      <li>
        <LocationIcon />
        Change Location
      </li>
      <li>
        <UserIcon /> Update BIO
      </li>
      <li>
        <LocationIcon />
        Need help with your claim?
      </li>
      <li onClick={() => navigate("/")}>
        <LogoutIcon /> Sign-out
      </li>
    </ul>
  );
};

const Profile = ({ classes }) => {
  const [active, setActive] = useState(false);
  return (
    <div
      className={`${style.root} ${classes}`}
      onClick={() => setActive((prev) => !prev)}
    >
      <span className={style.text}>Chidi</span>
      {active ? <CaretUpIcon /> : <CaretDownIcon />}

      <Modal
        Component={Settings}
        isActive={active}
        classes={style.modal}
        hasCloseBtn={false}
      />
    </div>
  );
};

export default Profile;
