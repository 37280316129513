import { useState } from "react";
import Button from "../../../components/forms/button/Button";
import InputField from "../../../components/forms/input_field/InputField";
import RadioButton from "../../../components/forms/radio_button/RadioButton";
import style from "./Screens.module.css";
import { toast } from "react-toastify";
import { RowLayout } from "../../../components/layouts/util/UtilityLayout";
import DatePicker from "../../../components/forms/date_picker/DatePicker";
import Dropdown from "../../../components/forms/dropdown/Dropdown";
import { CircleAddIcon } from "../../../assets/icons/icons";
import {
  dateExtractor,
  isValidDate,
  isValidNumber,
} from "../../../services/util_functions";

const Screen7 = ({ labels, updatePage, save, defaultValue, generalLabel }) => {
  const { label1, label2, label3, label4, label5, label6 } = labels;
  const [form, setForm] = useState(defaultValue);

  const initTreatmentDate = dateExtractor(defaultValue?.treatmentDate || "");
  const initFirstClinicalSignDate = dateExtractor(
    defaultValue?.firstClinicalSignDate || ""
  );

  const prevScreenHandler = () => updatePage(5);
  const nextScreenHandler = () => {
    //  validation
    if (!form?.isContinuationClaim?.label) {
      toast.error(`Select an option for ${label1}`);
    } else if (
      !form?.condition ||
      form?.condition?.toLowerCase() === "select"
    ) {
      toast.error("Select a valid condition being claimed for");
    } else if (
      !isValidDate(form?.treatmentDate) ||
      !isValidDate(form?.firstClinicalSignDate)
    ) {
      toast.error(
        `Enter valid Date for both treatment and first clinical sign.`
      );
    } else if (!isValidNumber(form?.totalClaimAmount)) {
      toast.error(
        `Enter a valid total claim amount. Eg x,xxx.xx or xxx or xxxx.xx or x.xx`
      );
    } else {
      save({ screen7: form });
      updatePage(7);
    }
  };

  return (
    <div className={style.screenScrollable}>
      <p>
        {label1} <span>*</span>
      </p>
      <RadioButton
        labels={[generalLabel.label1, generalLabel.label2]}
        selectedOption={form?.isContinuationClaim}
        type="checkbox"
        classesLabel={style.shrink}
        classesInput={style.shrinkInput}
        noGap={true}
        display="row"
        parentName={label1}
        callbackFn={(data) =>
          setForm((prev) => ({ ...prev, isContinuationClaim: data }))
        }
      />

      <Dropdown
        label={label2}
        defaultValue={form?.condition}
        listItem={[
          "Condition name 1",
          "Conditiona name 2",
          "Conditiona name 3",
        ]}
        classes={style.dropdown}
        listClasses={style.zIndex1000}
        required={true}
        callbackFn={(data) => setForm((prev) => ({ ...prev, condition: data }))}
      />

      <hr className={style.hr80} />
      <p className={`${style.mb20} ${style.align}`}>
        {label3} <CircleAddIcon siz={20} />
      </p>
      <DatePicker
        label={label4}
        required={true}
        name="treatmentDate"
        defaultValue={initTreatmentDate}
        callback={(value) =>
          setForm((prev) => ({
            ...prev,
            treatmentDate: `${value.treatmentDate.year}-${value.treatmentDate.month}-${value.treatmentDate.day}`,
          }))
        }
        classes={style.zIndex990}
      />
      <DatePicker
        label={label5}
        required={true}
        name="firstClinicalSignDate"
        defaultValue={initFirstClinicalSignDate}
        callback={(value) =>
          setForm((prev) => ({
            ...prev,
            firstClinicalSignDate: `${value.firstClinicalSignDate.year}-${value.firstClinicalSignDate.month}-${value.firstClinicalSignDate.day}`,
          }))
        }
        classes={style.zIndex980}
      />
      <InputField
        label={label6}
        value={form?.totalClaimAmount}
        type="text"
        name="totalClaimAmount"
        placeholder=""
        required={true}
        classes={style.inputAmount}
        callbackFn={(value) => setForm((prev) => ({ ...prev, ...value }))}
      />

      <RowLayout classes={style.gap20}>
        <Button
          text={generalLabel.label4}
          onClick={prevScreenHandler}
          classes={style.btn}
        />
        <Button
          text={generalLabel.label3}
          onClick={nextScreenHandler}
          classes={style.btn}
        />
      </RowLayout>
    </div>
  );
};
export default Screen7;
