import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import IndexRoutes from "./routes/index_route";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { store, persistor } from "./redux/store";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IndexRoutes />
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
        />
      </PersistGate>
    </Provider>
  );
}

export default App;
