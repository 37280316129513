import { useState, useEffect } from "react";
import style from "./InputField.module.css";
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";
// import _debounce from "lodash/debounce";
import { RowLayout } from "../../layouts/util/UtilityLayout";
import { validateEmail } from "../../../services/util_functions";

const InputField = ({
  label,
  type,
  placeholder,
  classes,
  name = "value",
  value = "",
  callbackFn = () => {},
  showValidationIcon = false,
  required = false,
  disabled = false,
  editable = true,
  whiteDisabledBg = false,
  ...rest
}) => {
  const [isValidInput, setIsValidInput] = useState(null);
  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    setDisplayValue(value);
  }, [value]);

  // const validateEmail = (email) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };

  const changeHandler = (e) => {
    const value = e.target.value;

    // validation
    let validationStatus = null;
    if (type === "email") {
      validationStatus = validateEmail(value);
    }

    // update local state
    setIsValidInput(validationStatus);
    setDisplayValue(value);
    // Debounce only the callback function to ensure that the local state is updated immediately
    callbackFn({ [name]: value, validation: validationStatus });
  };

  // const debounceCallback = _debounce(callbackFn, 500);

  const labelColor = isValidInput ? "green" : "red";

  return (
    <div style={{ pointerEvents: disabled === true ? "none" : "auto" }}>
      <p className={`${style.label}`}>
        {label} {required && <span>*</span>}
      </p>
      <RowLayout classes={style.align}>
        <input
          type={type}
          placeholder={placeholder}
          onChange={changeHandler}
          value={displayValue}
          disabled={disabled}
          className={`${style.input} ${classes} ${
            whiteDisabledBg ? style.whiteDisabledBg : ""
          }`}
        />{" "}
        {showValidationIcon && (
          <span className={`${style[labelColor]}`}>
            {isValidInput ? (
              <CiCircleCheck />
            ) : isValidInput === null ? (
              ""
            ) : (
              <CiCircleRemove />
            )}
          </span>
        )}
      </RowLayout>
    </div>
  );
};

export default InputField;
