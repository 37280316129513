import {
  CaretDownIcon,
  CaretUpIcon,
  WorldIcon,
} from "../../assets/icons/icons";
import Modal from "../modal/Modal";
import style from "./LanguageSelector.module.css";
import { useState } from "react";

export const Languages = () => {
  return (
    <ul className={style.list}>
      <li>EN</li>
      <li>DE</li>
    </ul>
  );
};

const LanguageSelector = ({ classes }) => {
  const [active, setActive] = useState(false);
  return (
    <div
      className={`${style.root} ${classes}`}
      onClick={() => setActive((prev) => !prev)}
    >
      <WorldIcon size={20} /> <span className={style.text}>EN</span>
      {active ? <CaretUpIcon /> : <CaretDownIcon />}
      <Modal
        Component={Languages}
        isActive={active}
        classes={style.modal}
        hasCloseBtn={false}
      />
    </div>
  );
};

export default LanguageSelector;
