import { useState } from "react";
import Button from "../../../components/forms/button/Button";
import InputField from "../../../components/forms/input_field/InputField";
import RadioButton from "../../../components/forms/radio_button/RadioButton";
import style from "./Screens.module.css";
import { toast } from "react-toastify";
import { RowLayout } from "../../../components/layouts/util/UtilityLayout";
import { isValidInput, validateEmail } from "../../../services/util_functions";
import Card from "../../../components/card/Card";

const Screen8 = ({ labels, updatePage, save, defaultValue, generalLabel }) => {
  const {
    label1,
    label2,
    label3,
    label4,
    label5,
    label6,
    label7,
    label8,
    label9,
  } = labels;
  const [form, setForm] = useState(defaultValue);
  const [reload, setReload] = useState(false);

  console.log(form);

  const [moreVetDetails, setMoreVetDetails] = useState(
    defaultValue?.vetDetails || [{}]
  );

  const [filesUploaded, setFilesUploaded] = useState(
    defaultValue?.filesUploaded || []
  );

  const [innerPage, setInnerPage] = useState(
    defaultValue?.uploadHistoryOrVetContact?.index !== undefined
      ? defaultValue?.uploadHistoryOrVetContact?.index + 1 // +1 will make up for the index position
      : 0
  );

  // Manage more vet details
  const moreVetHandler = (index, value) => {
    const temp = { ...moreVetDetails[index], ...value };
    const newArray = moreVetDetails.map((item, indx) =>
      index === indx ? temp : item
    );
    setMoreVetDetails(newArray);
  };

  // console.log("all ", moreVetDetails);

  const fileUploadHandler = (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];

    if (file) {
      // Check if the file is a JPEG or PNG
      if (file.type === "image/jpeg" || file.type === "image/png") {
        // Check file size (e.g., limit to 1 MB)
        const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB
        if (file.size > maxSizeInBytes) {
          toast.error(
            "File size exceeds the limit (1 MB). Please select a smaller file."
          );
          return; // Stop further processing if the file size exceeds the limit
        } else {
          // Get file name
          const fileName = file.name;
          // console.log("File Name:", fileName);

          // Now you can proceed with further actions, such as uploading the file to a server
          // console.log("Selected file:", file);
          // ... (add your file handling logic here)
          // const rawData= file // save the file with the api call
          setFilesUploaded((prev) => [...prev, { name: fileName }]);
        }
      } else {
        toast.error("Invalid File Type. Please select a JPEG or PNG file.");
        return; // Stop further processing if the file type is not allowed
      }
    }
  };

  const prevScreenHandler = () => updatePage(6);
  const prevPage = () => setInnerPage(0);

  const nextPage = () => {
    //  validation
    if (
      !form.firstOrLastClaim?.label ||
      !form?.uploadHistoryOrVetContact?.label
    ) {
      toast.error(`All fields with * are required.`);
    } else {
      setInnerPage((prev) => form?.uploadHistoryOrVetContact?.index + 3);
    }
  };

  const nextScreenHandler = () => {
    let status = false;
    if (form?.uploadHistoryOrVetContact?.index === 1) {
      for (const vet of moreVetDetails) {
        if (!validateEmail(vet?.vetEmail)) {
          toast.error(`Invalid email address "${vet?.vetEmail || ""}"`);
          break;
        }
        if (!isValidInput([vet?.vetPhoneNumber])) {
          toast.error("Phone number is required");
          break;
        } else {
          status = true;
        }
      }
    } else if (form?.uploadHistoryOrVetContact?.index === 0) {
      if (!form?.fileUploadType?.label) {
        toast.error("Select an option");
      } else if (
        form?.uploadHistoryOrVetContact?.index === 0 &&
        filesUploaded.length < 1
      ) {
        toast.error("You are yet to upload supporting document");
      } else {
        status = true;
      }
    }
    if (status) {
      let updatedForm = {};
      if (form?.uploadHistoryOrVetContact?.index === 1) {
        updatedForm = {
          ...form,
          vetDetails: moreVetDetails,
          filesUploaded: [],
          fileUploadType: {},
        };
      } else {
        updatedForm = { ...form, filesUploaded: filesUploaded, vetDetails: [] };
      }
      save({ screen8: updatedForm });
      updatePage(8);
    }
  };

  return (
    <div className={style.screenScrollable} key={reload}>
      {innerPage < 3 && (
        <>
          <p>
            {label1} <span>*</span>
          </p>
          <RadioButton
            labels={[generalLabel.label1, generalLabel.label2]}
            selectedOption={form?.firstOrLastClaim}
            type="checkbox"
            classesLabel={style.shrink}
            classesInput={style.shrinkInput}
            noGap={true}
            display="row"
            parentName={label1}
            callbackFn={(data) =>
              setForm((prev) => ({ ...prev, firstOrLastClaim: data }))
            }
          />
          <p className={`${style.mb20} ${style.mt20}`}>
            {label2} <span>*</span>
          </p>
          <RadioButton
            labels={[label3, label4]}
            selectedOption={form?.uploadHistoryOrVetContact}
            type="checkbox"
            classesLabel={style.shrink}
            classesInput={style.shrinkInput}
            noGap={true}
            display="column"
            parentName={label3}
            callbackFn={(data) => {
              if (data.index === 0 || data.index === 1) {
                setInnerPage(data.index + 1);
              } else {
                // If currentIndex is not 0 or 1, go back to the first page
                setInnerPage(0);
              }
              setForm((prev) => ({
                ...prev,
                uploadHistoryOrVetContact: data,
              }));
            }}
          />
        </>
      )}
      {/* 3 and 4 is used to prevent the form from showing without the next button clicked. */}
      {innerPage === 3 && (
        <>
          <h4>{label3}</h4>
          <RadioButton
            labels={[label5, label6]}
            selectedOption={form?.fileUploadType}
            type="radio"
            classesLabel={style.shrink}
            classesInput={style.shrinkInput}
            noGap={true}
            display="column"
            parentName={label6}
            callbackFn={(data) => {
              // console.log(data);
              setForm((prev) => ({ ...prev, fileUploadType: data }));
            }}
          />

          {/* Image uploaded */}
          {form?.fileUploadType?.label && (
            <Card classes={style.card}>
              <div className={style.addMore}>
                <label>
                  <span className={style.btnAddMore}>ADD MORE</span>
                  {form?.fileUploadType?.index === 1 ? (
                    <input
                      type="file"
                      accept="image/*"
                      capture="camera"
                      onChange={fileUploadHandler}
                      className={style.hide}
                    />
                  ) : (
                    <input
                      type="file"
                      onChange={fileUploadHandler}
                      className={style.hide}
                    />
                  )}
                </label>
              </div>
              <RowLayout classes={style.fileHeader}>
                <div className={style.fileNo}>#</div>
                <div className={style.fileName}>File Name</div>
                <div className={style.fileAction}>Action</div>
              </RowLayout>
              {(filesUploaded || []).map((item, index) => (
                <>
                  <RowLayout classes={style.fileRecords} key={index}>
                    <div className={style.fileNo}>{index + 1}</div>
                    <div className={style.fileName}>{item.name}</div>
                    <div
                      className={style.fileAction}
                      onClick={() =>
                        setFilesUploaded((prev) =>
                          prev.filter((item, i) => index !== i)
                        )
                      }
                    >
                      DELETE
                    </div>
                  </RowLayout>
                  {/* <hr className={style.hr80} /> */}
                </>
              ))}
            </Card>
          )}
        </>
      )}
      {innerPage === 4 && (
        <>
          <h4>{label4}</h4>

          {moreVetDetails.map((item, indx) => (
            <div key={indx}>
              <InputField
                label={label7}
                value={moreVetDetails[indx]?.vetPhoneNumber}
                type="text"
                name="vetPhoneNumber"
                placeholder=""
                required={true}
                classes={style.input}
                callbackFn={(value) => moreVetHandler(indx, value)}
              />
              <InputField
                label={label8}
                value={moreVetDetails[indx]?.vetEmail}
                type="email"
                name="vetEmail"
                showValidationIcon={true}
                placeholder=""
                required={true}
                classes={style.input}
                callbackFn={(value) => moreVetHandler(indx, value)}
              />
              <RadioButton
                labels={[label9]}
                selectedOption={item}
                type="checkbox"
                classesLabel={style.shrink}
                classesInput={style.shrinkInput}
                noGap={true}
                display="column"
                callbackFn={(data) => {
                  if (data.index === 0) {
                    const updatedArr = moreVetDetails.map((val, i) => ({
                      ...val,
                      index: 0,
                    }));
                    setMoreVetDetails([...updatedArr, {}]);
                  } else {
                    const updatedDetails = [...moreVetDetails];
                    updatedDetails.splice(indx, 1);

                    const updatedArr = updatedDetails.map((val, i, arr) => {
                      if (i <= arr.length - 2) {
                        return { ...val, index: 0 };
                      } else {
                        return { ...val, index: null };
                      }
                    });
                    setMoreVetDetails(updatedArr);
                    setReload((prev) => !prev);
                  }
                }}
              />
            </div>
          ))}
        </>
      )}
      <RowLayout classes={style.gap20}>
        <Button
          text={generalLabel.label4}
          onClick={innerPage >= 3 ? prevPage : prevScreenHandler}
          classes={style.btn}
        />
        <Button
          text={generalLabel.label3}
          onClick={innerPage < 3 ? nextPage : nextScreenHandler}
          classes={style.btn}
        />
      </RowLayout>
    </div>
  );
};
export default Screen8;
