import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import claimManager from "./features/claimSlice";

const persistConfig = {
  key: "root",
  storage,
  // whitelist: ["claimManager"],
};

const rootReducer = combineReducers({
  claimManager,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
