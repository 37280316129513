import { useState } from "react";
import Button from "../../../components/forms/button/Button";
import InputField from "../../../components/forms/input_field/InputField";
import style from "./Screens.module.css";
// import { toast } from "react-toastify";
import { RowLayout } from "../../../components/layouts/util/UtilityLayout";
import Dropdown from "../../../components/forms/dropdown/Dropdown";

const Screen3 = ({
  labels,
  defaultValue = {},
  updatePage,
  save,
  generalLabel,
}) => {
  const { label1, label2, label3, label4, label5 } = labels;
  const [form, setForm] = useState(defaultValue);

  const prevScreenHandler = () => updatePage(1);
  const nextScreenHandler = () => {
    save({ screen3: { ...form } });
    updatePage(3);
  };

  return (
    <div className={style.screenScrollable}>
      <p className={`${style.title} ${style.bigFont}`}>{label1}</p>
      <Dropdown
        label={label2}
        defaultValue={form?.policyNumber || "Policy ID 1"}
        listItem={["Policy ID 1", "Policy ID 2"]}
        classes={style.dropdown}
        callbackFn={(value) =>
          setForm((prev) => ({ ...prev, policyNumber: value }))
        }
      />

      <InputField
        label={label3}
        type="text"
        value={form.petName || "Jack"}
        disabled={true}
        classes={style.input}
      />

      <InputField
        label={label4}
        type="text"
        value={form.petDOB || "2019-01-12"}
        disabled={true}
        classes={style.input}
      />
      <p>{label5}</p>
      <RowLayout classes={style.gap20}>
        <Button
          text={generalLabel.label4}
          onClick={prevScreenHandler}
          classes={style.btn}
        />
        <Button
          text={generalLabel.label5}
          onClick={nextScreenHandler}
          classes={style.btnConfirm}
        />
      </RowLayout>
    </div>
  );
};
export default Screen3;
