import DashboardLayout from "../../components/layouts/dashboard/DashboardLayout";
import style from "./AddClaim.module.css";
import ScreenView from "./screens/Screens";

const AddClaim = () => {
  return (
    <div className={style.root}>
      <DashboardLayout Component={ScreenView} />
    </div>
  );
};

export default AddClaim;
