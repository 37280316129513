import { useState, useEffect, useRef } from "react";
import style from "./Dropdown.module.css";
import InputField from "../input_field/InputField";
import { CaretDownIcon, CaretUpIcon } from "../../../assets/icons/icons";

export const Display = ({ data, callback, listItem, listClasses }) => {
  const [optionSelected, setOptionSelected] = useState(data);
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef(null);

  // Populate from api
  // Allows closing modal by clicking body of the page
  useEffect(() => {
    const handleGlobalClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    const handleDocumentClick = () => {
      document.addEventListener("mousedown", handleGlobalClick);
    };

    handleDocumentClick();

    return () => {
      document.removeEventListener("mousedown", handleGlobalClick);
    };
  }, []);

  useEffect(() => {
    setOptionSelected(data);
  }, [data]);

  const updateActiveStateHandler = () => {
    setIsActive((prev) => !prev);
  };

  const updateSelectionHandler = (selectedOption) => {
    setOptionSelected(selectedOption);
    callback(selectedOption);
    setIsActive((prev) => !prev);
  };

  return (
    <div ref={dropdownRef}>
      <div className={style.display} onClick={updateActiveStateHandler}>
        <InputField
          value={optionSelected}
          classes={`${style.input} `}
          disabled={true}
          whiteDisabledBg={true}
        />
        <span className={style.icon}>
          {isActive ? <CaretUpIcon /> : <CaretDownIcon />}
        </span>
      </div>
      {isActive && (
        <List
          data={listItem}
          updateSelection={updateSelectionHandler}
          listClasses={listClasses}
        />
      )}
    </div>
  );
};

export const List = ({ data, updateSelection, listClasses }) => {
  return (
    <div className={`${style.listWrapper} ${listClasses}`}>
      <ul className={`${style.list}`}>
        {data.map((item, index) => (
          <li key={index} onClick={() => updateSelection(item)}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Dropdown = ({
  label,
  classes,
  listClasses = "",
  defaultValue = "",
  listItem = [],
  required = false,
  disabled = true,
  callbackFn = () => {},
}) => {
  const updateDataHandler = (data) => {
    callbackFn(data);
  };

  return (
    <div className={classes}>
      <p className={style.label}>
        {label} {required && <span>*</span>}
      </p>
      <div className={style.rootDisplay}>
        <Display
          data={defaultValue}
          callback={updateDataHandler}
          disabled={disabled}
          listItem={listItem}
          listClasses={listClasses}
        />
      </div>
    </div>
  );
};
export default Dropdown;
