import { useState } from "react";
import Button from "../components/forms/button/Button";
import InputField from "../components/forms/input_field/InputField";
import AuthLayout from "../components/layouts/authentication/AuthLayout";
import {
  ColumnLayout,
  RowLayout,
} from "../components/layouts/util/UtilityLayout";
import Modal from "../components/modal/Modal";
import { APP_CONTENT } from "../config/languages/content_loader";
import style from "./Login.module.css";
import useLogin from "../hooks/login/useLogin";

export const AccountType = () => {
  const { label1, label2, label3, label4 } = APP_CONTENT.accountType;
  const { navigate } = useLogin();
  return (
    <ColumnLayout className={style.accountType}>
      <div className={style.modalTitle}>
        <h4>{label1}</h4>
        <p className={style.subheading}>{label4}</p>
      </div>
      <RowLayout>
        <div
          className={`${style.accountTypeBtn}`}
          onClick={() => navigate("/signup/customer")}
        >
          {label2}
        </div>
        <div
          className={`${style.accountTypeBtn}`}
          onClick={() => navigate("/signup/veterinarian")}
        >
          {label3}
        </div>
      </RowLayout>
    </ColumnLayout>
  );
};

export const LoginForm = () => {
  const { label1, label2, label3, label4, label5, label6, label7, label8 } =
    APP_CONTENT.loginContent;
  const { navigate } = useLogin();

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal((prev) => !prev);
  };

  const loginHandler = () => {
    // do login validation
    navigate("/dashboard");
  };

  return (
    <div className={style.login}>
      <h1>{label1}</h1>
      <p className={style.subheading}>{label2}</p>
      <div className={style.form}>
        <InputField
          label={label3}
          name={label3}
          type="email"
          placeholder=""
          classes={style.input}
          callbackFn={(e) => console.log(e)}
        />
        <InputField
          label={label4}
          name={label4}
          type="password"
          placeholder=""
          classes={style.input}
          callbackFn={(e) => console.log(e)}
        />
        <p
          className={`${style.right} ${style.link}`}
          onClick={() => navigate("/reset-password")}
        >
          {label5}
        </p>
        <Button text={label7} onClick={loginHandler} />
        <p className={style.center}>
          {label6}
          <span className={style.link} onClick={toggleModal}>
            {label8}
          </span>
        </p>
      </div>
      <Modal
        Component={AccountType}
        isActive={modal}
        onClick={toggleModal}
        classes={style.modal}
      />
    </div>
  );
};

const Login = () => <AuthLayout Component={LoginForm} />;

export default Login;
