import { useState } from "react";
import Button from "../../../components/forms/button/Button";
import InputField from "../../../components/forms/input_field/InputField";
import RadioButton from "../../../components/forms/radio_button/RadioButton";
import style from "./Screens.module.css";
import { toast } from "react-toastify";

const Screen1 = ({
  defaultValue = {},
  labels,
  generalLabel,
  updatePage,
  save,
}) => {
  const { label1, label2, label3, label4 } = labels;
  const [form, setForm] = useState(defaultValue);

  const clickHandler = () => {
    //  validation
    if (!form.label) {
      toast.error(generalLabel.label6);
    } else if (form.index === 1 && !form.VetEmail) {
      toast.error(generalLabel.label7);
    } else if (form.index === 1 && !form.validation) {
      toast.error(generalLabel.label8);
    } else {
      // Save screen data
      save({ screen1: form });
      // Next page
      updatePage(1);
    }
  };

  return (
    <div className={style.screenScrollable}>
      <p className={`${style.title} ${style.bigFont} ${style.mb20}`}>
        {label1}
      </p>

      <RadioButton
        selectedOption={form}
        labels={[label2, label3]}
        type="checkbox" //display type can be radio or checkbox. The view will depend on the type selected but the
        // functionality is that of a group of radio button
        callbackFn={(data) =>
          setForm((prev) => {
            if (data.label === "") return {};
            // remove vet eamil from form if the first option is selected.
            else if (data.index === 0) return { ...data };
            return { ...prev, ...data };
          })
        }
      />
      <div className={style.mt20}>
        {form.index === 1 && (
          <InputField
            label={label4}
            type="email"
            name="VetEmail"
            value={form?.VetEmail || ""}
            placeholder={generalLabel.label9}
            showValidationIcon={true}
            required={true}
            classes={style.input}
            callbackFn={(value) => setForm((prev) => ({ ...prev, ...value }))}
          />
        )}
      </div>
      <Button
        text={generalLabel.label3}
        onClick={clickHandler}
        classes={style.btn}
      />
    </div>
  );
};
export default Screen1;
