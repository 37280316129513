import { useState } from "react";
import Button from "../../../components/forms/button/Button";
import InputField from "../../../components/forms/input_field/InputField";
import RadioButton from "../../../components/forms/radio_button/RadioButton";
import style from "./Screens.module.css";
import { toast } from "react-toastify";
import { RowLayout } from "../../../components/layouts/util/UtilityLayout";
import DatePicker from "../../../components/forms/date_picker/DatePicker";
import HelpResources from "../../../components/help_resources/HelpResources";
import { CaretDownIcon, CaretUpIcon } from "../../../assets/icons/icons";
import {
  dateExtractor,
  isValidDate,
  isValidInput,
} from "../../../services/util_functions";

const Screen2 = ({
  labels,
  defaultValue = {
    policyNumber: "",
    petName: "",
    petDOB: "",
    insuranceCompany: "",
  },
  updatePage,
  save,
  generalLabel,
}) => {
  const {
    label1,
    label2,
    label3,
    label4,
    label5,
    label6,
    label7,
    label8,
    label9,
    label9a,
    label10,
    label11,
    label12,
    label13,
    label14,
  } = labels;

  const [form, setForm] = useState(defaultValue);
  const [toggleProfile, setToggleProfile] = useState(true);

  // const dateArr = defaultValue?.petDOB?.split("-");
  // const defaultDate = {};
  // if (dateArr?.length > 0) {
  //   defaultDate["year"] = parseInt(dateArr[0]);
  //   defaultDate["month"] = parseInt(dateArr[1]);
  //   defaultDate["day"] = parseInt(dateArr[2]);
  // }

  const defaultDate = dateExtractor(defaultValue?.petDOB || "");

  const dataChangedHandler = (data) => {
    const status = data.label !== "" && data.label !== undefined ? "Yes" : "No";
    setForm((prev) => ({
      ...prev,
      dataChanged: { label: data.label, answer: status, index: data.index },
    }));
  };

  const insuredElseWhereHandler = (data) => {
    setForm((prev) => ({
      ...prev,
      insuredElseWhere: {
        question: data.parentName,
        label: data.label,
        index: data.index,
      },
    }));
  };

  // const isValidInput = (arr) => {
  //   let status = true;
  //   arr.forEach((item) => {
  //     if (item === undefined || item?.length < 1) {
  //       status = false;
  //       return;
  //     }
  //   });
  //   return status;
  // };

  // const isValidDate = (_date) => {
  //   const pattern = /YYYY|MM|DD/;
  //   if (!!_date === true && !pattern.test(_date)) {
  //     return true;
  //   }
  //   return false;
  // };

  // Manage this with redux
  const prevScreenHandler = () => updatePage(0);
  const nextScreenHandler = () => {
    //  validation
    if (!!form?.insuredElseWhere?.label !== true) {
      toast.error(`Select an option for "${label12}"`);
    } else if (
      form?.insuredElseWhere?.label === "Yes" &&
      !isValidInput([form?.insuranceCompany])
    ) {
      toast.error("Insurance company name is required");
    } else if (
      form?.dataChanged?.answer === "Yes" &&
      (!isValidInput([form?.policyNumber, form?.petName]) ||
        !isValidDate(form?.petDOB))
    ) {
      toast.error(`All fields with "*" are required for pet.`);
    } else {
      save({ screen2: form });
      updatePage(2);
    }
  };

  return (
    <div className={style.screenScrollable}>
      {/* call help component */}
      <div className={style.mobileOnly}>
        {/* Use redux to toggle on and off */}
        <HelpResources />
      </div>
      <div>
        <h4
          className={`${style.title} ${style.align}`}
          onClick={() => setToggleProfile((prev) => !prev)}
        >
          {label1}
          <span className={style.p10}>
            {toggleProfile ? <CaretUpIcon /> : <CaretDownIcon />}
          </span>
        </h4>
        {/* User details */}
        {toggleProfile && (
          <>
            <InputField
              type="text"
              name="customerName"
              label={label2}
              value="Chidi Duru"
              disabled={true}
              // required={true}
              classes={style.input}
              callbackFn={(value) => setForm((prev) => ({ ...prev, ...value }))}
            />
            <InputField
              type="text"
              name="phone"
              label={label3}
              value="0899516678"
              disabled={true}
              // required={true}
              classes={style.input}
              callbackFn={(value) => setForm((prev) => ({ ...prev, ...value }))}
            />
            <InputField
              type="email"
              name="email"
              label={label4}
              value="test@gmail.com"
              disabled={true}
              // required={true}
              classes={style.input}
              callbackFn={(value) => setForm((prev) => ({ ...prev, ...value }))}
            />
            <InputField
              type="text"
              name="address1"
              label={label5}
              value=""
              disabled={true}
              // required={true}
              classes={style.input}
              callbackFn={(value) => setForm((prev) => ({ ...prev, ...value }))}
            />
            <InputField
              type="text"
              name="address2"
              label={label6}
              value=""
              disabled={true}
              // required={true}
              classes={style.input}
              callbackFn={(value) => setForm((prev) => ({ ...prev, ...value }))}
            />{" "}
            <InputField
              type="text"
              name="town"
              label={label7}
              value=""
              disabled={true}
              // required={true}
              classes={style.input}
              callbackFn={(value) => setForm((prev) => ({ ...prev, ...value }))}
            />
            <InputField
              type="text"
              name="postCode"
              label={label8}
              value=""
              disabled={true}
              // required={true}
              classes={style.input}
              callbackFn={(value) => setForm((prev) => ({ ...prev, ...value }))}
            />
          </>
        )}
        <div className={style.mt10}></div>
        <RadioButton
          selectedOption={form?.dataChanged || {}}
          labels={[label9a]}
          type="checkbox" //display type can be radio or checkbox. The view will depend on the type selected but the
          // functionality is that of a group of radio button
          classesLabel={style.shrink}
          classesInput={style.shrinkInput}
          noGap={true}
          parentName="dataChanged"
          callbackFn={dataChangedHandler}
        />
      </div>
      <div className={style.mt20}>
        {form?.dataChanged?.answer === "Yes" && (
          <>
            <InputField
              label={label9}
              value={form.policyNumber}
              type="text"
              name="policyNumber"
              placeholder=""
              required={true}
              classes={style.input}
              callbackFn={(value) => setForm((prev) => ({ ...prev, ...value }))}
            />
            <InputField
              label={label10}
              value={form.petName}
              type="text"
              name="petName"
              placeholder=""
              required={true}
              classes={style.input}
              callbackFn={(value) => setForm((prev) => ({ ...prev, ...value }))}
            />

            <DatePicker
              label={label11}
              required={true}
              name="petDOB"
              // minDate naming should be changed.
              defaultValue={defaultDate}
              // If nothing is passed for minDate, current date will be used. Check datepicker for format
              callback={(value) =>
                setForm((prev) => ({
                  ...prev,
                  petDOB: `${value.petDOB.year}-${value.petDOB.month}-${value.petDOB.day}`,
                }))
              }
            />
          </>
        )}
      </div>
      <p>
        {label12} <span>*</span>
      </p>
      <RadioButton
        selectedOption={form?.insuredElseWhere || {}}
        labels={[generalLabel.label1, generalLabel.label2]}
        type="checkbox" //display type can be radio or checkbox. The view will depend on the type selected but the
        // functionality is that of a group of radio button
        classesLabel={style.shrink}
        classesInput={style.shrinkInput}
        noGap={true}
        display="row"
        parentName={label12}
        callbackFn={insuredElseWhereHandler}
      />
      {form?.insuredElseWhere?.label === "Yes" && (
        <>
          <p>
            {label13} <span>*</span>
          </p>
          <InputField
            type="text"
            name="insuranceCompany"
            placeholder={label14}
            value={form.insuranceCompany}
            // showValidationIcon={true}
            // required={true}
            classes={style.input}
            callbackFn={(value) => setForm((prev) => ({ ...prev, ...value }))}
          />
        </>
      )}
      <RowLayout classes={style.gap20}>
        <Button
          text={generalLabel.label4}
          onClick={prevScreenHandler}
          classes={style.btn}
        />
        <Button
          text={generalLabel.label3}
          onClick={nextScreenHandler}
          classes={style.btn}
        />
      </RowLayout>
    </div>
  );
};
export default Screen2;
