export const loginContent = {
  label1: "Welcome back.",
  label2: "Please enter your login credentials to continue",
  label3: "Email Address",
  label4: "Password",
  label5: "Forgot password?",
  label6: "Don't have an account?",
  label7: "Login",
  label8: "Signup",
  label9: "EasyClaim",
};
export const accountType = {
  label1: "Account Type",
  label2: "Customer",
  label3: "Veterinarian",
  label4: "Select an account type to register",
};

export const registratonFormContent = {
  label1: "Create an Account",
  label2: "Account type ",
  label3: "Client Number",
  label4: "Clinic/Practice Name",
  label5: "Email Address",
  label6: "Vet's Email Address",
  label7: "Password",
  label8: "Confirm Password",
  label9: "Sign-Up",
  label10: "Do you have an account? ",
  label11: "Login",
};

export const retrievePasswordContent = {
  label1: "Rest Password",
  label2: "Email Address",
  label3: "Enter OTP",
  label4: "New Password",
  label5: "Confirm Password",
  label6: "Submit",
  label7: "Did not get OTP on email? ",
  label8: "Resend OTP",
  label9: "GET OTP",
};

export const generalContent = {
  label1: "Yes",
  label2: "No",
  label3: "Next",
  label4: "Previous",
  label5: "Confirm",
  label6: "Select an option",
  label7: "Vet email address is required",
  label8: "Enter a valid email address",
  label9: "Enter email address",
  label10: "Submit",
  label11: "Close",
};

export const customerClaimsContent = [
  {
    label1: "How would you like to lodge your claim?",
    label2: "I will provide all claim related data and documents.",
    label3:
      "My Vet will assist me and lodge the claim on my behalf (the Vet should be a registered user of this application).",
    label4: "My Vet's email address",
  },
  {
    label1: "Your details",
    label2: "Customer's name",
    label3: "Phone number",
    label4: "Email Address",
    label5: "Address line 1",
    label6: "Address line 2",
    label7: "Town",
    label8: "Postcode",
    label9a: "Has your data changed?",
    label9: "Policy number",
    label10: "Pet's name",
    label11: "Pet's date of birth",
    label12: "Is your pet insured with any other company?",
    label13: "What is the name of the insurance company?",
    label14: "Enter company's name",
  },
  {
    label1:
      "Please select/confirm the policy number you would like to make a claim on.",
    label2: "Policy number",
    label3: "Pet's name",
    label4: "Pet's date of birth",
    label5: "We will email your Vet to start the claim process on your behalf.",
  },
  {
    label1: "Your Vet hasn't been registered with the given email address.",
    label2:
      "I will contact my Vet to register for them to be able to lodge a claim on my behalf",
    label3:
      "I wish to proceed with the claim process without my Vet's assistance",
  },
  {
    label1: "Is it the first claim regarding this condition of ",
    label2: "Is the amount claimed under ",
    label3:
      "Please note that we only start paying a claim once it is over the excess amount, so you can submit the claim that amount is reached. If the overall treatment is not going to exceed the excess, you should still submit the claim so that we are able to add this to the policy history, or should you need to claim for any further treatment.",
  },
  {
    label1: "Your claim related to ",
    label2: "illness",
    label3: "injury",
    label4:
      "The waiting period is still in effect for this policy, please call our customer service on ",
  },
  {
    label1: "Is this claim a continuation of a previous claim?",
    label2: "Condition being claimed for",
    label3: "Additional field",
    label4: "Treatment date (dd/mm/yyyy)",
    label5: "Date of first clinical signs (dd/mm/yyyy)",
    label6: "Total charge (in appropriate currency)",
  },
  {
    label1:
      "Is this your first claim or your last claim was more than 12 months ago on this policy?",
    label2:
      "Please attach the full clinical history from both current and previous veterinary practices or provide your Vet's/ Vets' contact details so that we can contact them for the clinical history.",
    label3: "Upload clinical history",
    label4: "My Vet's contact details",
    label5: "Attach file",
    label6: "Take a photo by phone",
    label7: "Phone number",
    label8: "Email Address",
    label9: "Add more vet(s)' details",
  },
  {
    label1: "How would you like the benefit to be paid?",
    label2: "Payment into my bank account",
    label3: "Please note, we can only credit amounts .....)",
    label4: "Paid to my vet directly",
    label5: "I have arranged with ....",
    label6: "Your bank account number",
    label8: "Is your bank account number correct?",
    label9: "Please provide your new bank account number",
    label10: "our customer service team will call you to confirm .....",
    label11: "Customer new bank account number",
    label12: "Vet's bank details",
    label13: "Vet's name",
    label14: "Vet's bank account number",
  },
  {
    label1: `I authorise Petcover *** to provide the verterinary practice with information about my policy in respect 
    of this claim and the veterinary practice to provide with all information relating to my pet. 
    I also confirm that I am the policy holder and I have checked the information given on this form 
    and that it is correct to the best of my knowledge.`,
    label2:
      "Please tick here, if you have read and acknowledged the above declaration",
  },
  {
    label1:
      "Thank you for submitting the claim, we have started to assess it for you. Our team will contact you shortly.",
    label2: "Your claim number:",
    label3: "Claim number",
  },
];
export const helpResourcesContent = {
  title: "Need help with your claim?",
  list: [
    {
      label: "How to place a claim?",
      type: "video",
      url: "https://www.youtube.com/embed/tgbNymZ7vqY",
    },
    {
      label: "Next steps after loading a claim?",
      type: "video",
      url: "https://www.youtube.com/embed/J_QGZspO4gg?si=n2M33qZe-P523new",
    },
    { label: "Claims under excess?", type: "video", url: "" },
    { label: "None claimable items?", type: "video", url: "" },
  ],
};

export const EN_CONTENT = {
  loginContent,
  accountType,
  registratonFormContent,
  retrievePasswordContent,
  customerClaimsContent,
  generalContent,
  helpResourcesContent,
};
