import { CloseIcon } from "../../../assets/icons/icons";
import style from "./Button.module.css";

const ButtonClose = ({ classes, onClick }) => {
  return (
    <button className={`${style.buttonClose} ${classes}`} onClick={onClick}>
      <CloseIcon />
    </button>
  );
};

export default ButtonClose;
