// import { DE_CONTENT } from "./de/de_content";
import { EN_CONTENT } from "./en/en_content";

const getContent = (language) => {
  switch (language) {
    // case "de":
    //   return DE_CONTENT;

    default:
      return EN_CONTENT;
  }
};

// TODO:: Get this from either localstorage or redux
const languageSelected = "en";

export const APP_CONTENT = getContent(languageSelected);
