// import { useState } from "react";
import { APP_CONTENT } from "../../../config/languages/content_loader";
import Screen1 from "./Screen1";
import Screen2 from "./Screen2";
import Screen3 from "./Screen3";
import Screen4 from "./Screen4";
import Screen5 from "./Screen5";
import Screen6 from "./Screen6";
import Screen7 from "./Screen7";
import Screen8 from "./Screen8";
import Screen9 from "./Screen9";
import Screen10 from "./Screen10";
import Screen11 from "./Screen11";
import { useDispatch, useSelector } from "react-redux";
import {
  updateScreenIndex,
  updateForm,
  clearForm,
} from "../../../redux/features/claimSlice";

const ScreenView = () => {
  const claimsContent = APP_CONTENT.customerClaimsContent;
  const generalLabel = APP_CONTENT.generalContent;

  const dispatch = useDispatch();
  const { screenIndex, form } = useSelector((store) => store.claimManager);
  const LABELS = claimsContent[screenIndex];

  const updatePage = (index) => dispatch(updateScreenIndex(index));
  const saveForm = (data) => dispatch(updateForm(data));
  const clearData = () => dispatch(clearForm());

  const ScreenLoader = () => {
    switch (screenIndex) {
      case 0:
        return (
          <Screen1
            labels={LABELS}
            generalLabel={generalLabel}
            defaultValue={form?.screen1 || {}}
            updatePage={updatePage}
            save={saveForm}
          />
        );
      case 1:
        return (
          <Screen2
            labels={LABELS}
            generalLabel={generalLabel}
            defaultValue={form?.screen2 || {}}
            updatePage={updatePage}
            save={saveForm}
          />
        );
      case 2:
        return (
          <Screen3
            labels={LABELS}
            generalLabel={generalLabel}
            defaultValue={
              form?.screen3 || {
                petName: "Jack",
                petDOB: "2019-01-12",
                policyNumber: "Policy ID 1",
              } // get data from api
            }
            updatePage={updatePage}
            save={saveForm}
          />
        );
      case 3:
        return (
          <Screen4
            labels={LABELS}
            generalLabel={generalLabel}
            defaultValue={form?.screen4 || {}}
            updatePage={updatePage}
            save={saveForm}
          />
        );
      case 4:
        return (
          <Screen5
            labels={LABELS}
            generalLabel={generalLabel}
            defaultValue={
              form?.screen5 || { petName: "Jack", excessAmount: 150.0 }
            }
            updatePage={updatePage}
            save={saveForm}
          />
        );
      case 5:
        return (
          <Screen6
            labels={LABELS}
            generalLabel={generalLabel}
            defaultValue={
              form?.screen6 || {
                customerCareNumber: "0899516678",
                petName: "Jack",
              }
            }
            updatePage={updatePage}
            save={saveForm}
          />
        );
      case 6:
        return (
          <Screen7
            labels={LABELS}
            generalLabel={generalLabel}
            defaultValue={form?.screen7 || { condition: "Select" }}
            updatePage={updatePage}
            save={saveForm}
          />
        );
      case 7:
        return (
          <Screen8
            labels={LABELS}
            generalLabel={generalLabel}
            defaultValue={form?.screen8 || {}}
            updatePage={updatePage}
            save={saveForm}
          />
        );
      case 8:
        return (
          <Screen9
            labels={LABELS}
            generalLabel={generalLabel}
            defaultValue={form?.screen9 || {}}
            updatePage={updatePage}
            save={saveForm}
          />
        );
      case 9:
        return (
          <Screen10
            labels={LABELS}
            generalLabel={generalLabel}
            defaultValue={form?.screen10 || {}}
            updatePage={updatePage}
            save={saveForm}
            clear={clearData}
            allData={form}
          />
        );
      case 10:
        return (
          <Screen11
            labels={LABELS}
            generalLabel={generalLabel}
            defaultValue={form?.screen11 || {}}
            updatePage={updatePage}
            save={saveForm}
          />
        );
      default:
        return <Screen1 data={{}} />;
    }
  };

  return <ScreenLoader />;
};

export default ScreenView;
