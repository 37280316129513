import style from "./Dashboard.module.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../../components/layouts/dashboard/DashboardLayout";
import { AddIcon } from "../../assets/icons/icons";
import ClaimSummary from "../../components/claim_summary/ClaimSummary";

export const ClaimList = ({ isActive }) => {
  return (
    isActive && (
      <div>
        <div className={style.action}>
          <Link to="/dashboard/add-claim">
            <AddIcon size={25} />
          </Link>
        </div>
        <div className={style.scrollable}>
          <ClaimSummary data={{}} />
        </div>
      </div>
    )
  );
};

export const PolicyList = ({ isActive }) => {
  return isActive && <div>In development</div>;
};

export const DashboardComponent = () => {
  const [toggleMenu, setToggleMenu] = useState({
    isClaim: true,
    isPolicy: false,
  });

  return (
    <>
      <ul className={style.menu}>
        <li
          className={toggleMenu?.isClaim || false ? style.active : ""}
          onClick={() => setToggleMenu((prev) => ({ isClaim: true }))}
        >
          My Claims
        </li>
        <li
          className={toggleMenu?.isPolicy || false ? style.active : ""}
          onClick={() => setToggleMenu((prev) => ({ isPolicy: true }))}
        >
          My Policies
        </li>
      </ul>
      <div>
        <ClaimList isActive={toggleMenu?.isClaim || false} />
        <PolicyList isActive={toggleMenu?.isPolicy || false} />
      </div>
    </>
  );
};

const Dashboard = () => {
  return (
    <div>
      <DashboardLayout Component={DashboardComponent} />
    </div>
  );
};

export default Dashboard;
