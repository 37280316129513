import style from "./Button.module.css";
const Button = ({ text, classes, onClick }) => {
  return (
    <button className={`${style.button} ${classes}`} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;
