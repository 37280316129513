import { useState } from "react";
import Button from "../../../components/forms/button/Button";
import RadioButton from "../../../components/forms/radio_button/RadioButton";
import style from "./Screens.module.css";
import { RowLayout } from "../../../components/layouts/util/UtilityLayout";
import { toast } from "react-toastify";
import Alert from "../../../components/alert/Alert";

const Screen4 = ({ defaultValue, updatePage, save, labels, generalLabel }) => {
  const { label1, label2, label3 } = labels;
  const [form, setForm] = useState(defaultValue);

  const prevScreenHandler = () => updatePage(2);
  const nextScreenHandler = () => {
    //  validation
    if (!form?.label) {
      toast.error(`Select an option `);
    } else {
      save({ screen4: form });
      updatePage(4);
    }
  };

  return (
    <div className={style.screenScrollable}>
      <Alert message={label1} type="warning" />

      <RadioButton
        labels={[label2, label3]}
        selectedOption={form}
        type="checkbox"
        callbackFn={(data) => setForm(data)}
      />

      <RowLayout classes={style.gap20}>
        <Button
          text={generalLabel.label4}
          onClick={prevScreenHandler}
          classes={style.btn}
        />
        <Button
          text={generalLabel.label3}
          onClick={nextScreenHandler}
          classes={style.btn}
        />
      </RowLayout>
    </div>
  );
};
export default Screen4;
