import ButtonClose from "../forms/button/ButtonClose";
import style from "./Modal.module.css";
const Modal = ({
  Component,
  classes,
  onClick,
  isActive = false,
  hasCloseBtn = true,
  data = {},
}) => {
  return (
    isActive && (
      <div className={`${style.modal} ${classes}`}>
        {hasCloseBtn && <ButtonClose onClick={onClick} />}
        <Component data={data} />
      </div>
    )
  );
};

export default Modal;
